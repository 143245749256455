import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "container", "content"]

  connect() {
    this.onScroll = this.onScroll.bind(this)
    this.onContainerUpdate = this.onContainerUpdate.bind(this)

    if (this.containerTargets.length) {
      const observer = new MutationObserver(this.onContainerUpdate)

      this.containerTargets.forEach(container => {
        container.addEventListener("scroll", this.onScroll)
        observer.observe(container, { childList: true, subtree: true })
      })
    }
  }

  disconnect() {
    if (this.containerTargets.length) {
      this.containerTargets.forEach(container => {
        container.removeEventListener("scroll", this.onScroll)
      })
    }
  }

  onContainerUpdate(mutationList, observer) {
    mutationList.forEach( (mutation) => {
      if (mutation.type == "childList") {
        this.toggleScrollButtons(mutation.target)
      }
    })
  }


  element(event) {
    event.preventDefault()
    let button = event.currentTarget
    let direction = button.dataset.direction
    let destination = document.querySelector(button.dataset.destination)

    if (direction == "left") {
      destination.scrollLeft -= 60
    } else {
      destination.scrollLeft += 60
    }
  }

  onScroll(event) {
    this.toggleScrollButtons(event.target)
  }

  toggleScrollButtons(target) {
    const container = this.getContainer(target)

    let leftButton = document.querySelector("button[data-destination='#" + container.id + "'][data-direction='left']")
    let rightButton = document.querySelector("button[data-destination='#" + container.id + "'][data-direction='right']")

    switch(this.checkScrollBar(container)) {
      case -1:
        leftButton.classList.add("disabled")
        rightButton.classList.add("disabled")

        leftButton.querySelector(".icon").classList.add("icon--disabled")
        rightButton.querySelector(".icon").classList.add("icon--disabled")
        break
      case 0:
        leftButton.classList.add("disabled")
        rightButton.classList.remove("disabled")

        leftButton.querySelector(".icon").classList.add("icon--disabled")
        rightButton.querySelector(".icon").classList.remove("icon--disabled")
        break
      case 100:
        leftButton.classList.remove("disabled")
        rightButton.classList.add("disabled")

        leftButton.querySelector(".icon").classList.remove("icon--disabled")
        rightButton.querySelector(".icon").classList.add("icon--disabled")
        break
      default:
        leftButton.classList.remove("disabled")
        rightButton.classList.remove("disabled")

        leftButton.querySelector(".icon").classList.remove("icon--disabled")
        rightButton.querySelector(".icon").classList.remove("icon--disabled")
    }
  }

  checkScrollBar(container) {
    let contents = container.querySelectorAll("table")[0]
    if (contents == null) return -1

    let scrollBarPosition = container.scrollLeft
    let elementWidth = container.offsetWidth
    let contentsWidth = contents.offsetWidth
    let widthDifference = contentsWidth - elementWidth

    if (widthDifference == 0) {
      return -1
    }
    else if (scrollBarPosition == 0) {
      return 0
    }
    else {
      return (scrollBarPosition / widthDifference) * 100
    }
  }

  getContainer(target) {
    if (target.dataset.destination) {
      return target
    } else {
      return target.closest("[data-scroll-target='container']")
    }
  }
}
