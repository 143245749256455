import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    isShown: Boolean
  }

  open() {
    this.isShownValue = true;
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    document.body.insertAdjacentHTML("beforeend", '<div class="modal-backdrop fade show"></div>');
  }

  close() {
    this.isShownValue = false;
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }
}
